import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    setting: [],
};

const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // GET CARD SUCCESS
        getUserSuccess(state, action) {
            state.isLoading = false;
            state.setting = action.payload;
        },
    },
});

export default settingSlice.reducer;

// Thunk action for fetching cards
export function getSetting() {
    return async (dispatch) => {
        dispatch(settingSlice.actions.startLoading());
        try {
            const response = await axios.get('setting');
            dispatch(settingSlice.actions.getUserSuccess(response?.data?.data));
        } catch (error) {
            dispatch(settingSlice.actions.hasError(error));
        }
    };
}
