/* eslint-disable arrow-body-style */
/* eslint-disable eqeqeq */
/* eslint-disable no-else-return */
import { Icon } from '@iconify/react';
// import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const getNavConfig = (userRole) => {
  if (userRole) {
    return [
      {
        items: [
          { title: 'Dashboard', path: '/dashboard/home', icon: <Icon icon="ri:dashboard-line" /> },
          { title: 'User', path: PATH_DASHBOARD.user.user, icon: ICONS.user },
          { title: 'Story', path: PATH_DASHBOARD.story.story, icon: <PeopleAltIcon /> },
          { title: 'Tribute', path: PATH_DASHBOARD.tribute.tribute, icon: ICONS.booking },
          { title: 'Gallery', path: PATH_DASHBOARD.gallery.gallery, icon: <PeopleAltIcon /> },
          { title: 'Setting', path: PATH_DASHBOARD.setting.setting, icon: <PeopleAltIcon /> },
          // { title: 'League', path: PATH_DASHBOARD.league.league, icon: ICONS.calendar },
          // { title: 'Add Tickets', path: PATH_DASHBOARD.matcheticket.matcheticket, icon: <PeopleAltIcon /> },
        ],
      },
    ];
  } else {
    return [];
  }
};

export default getNavConfig;
