/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import { Outlet } from 'react-router-dom';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// @mui
import { Box, Stack } from '@mui/material';
//
import MainHeader from './MainHeader';
import MainFooter from './MainFooter';
// ----------------------------------------------------------------------

export default function MainLayout() {
    return (
        <>
            <Stack sx={{ height: '60px' }}>
                <MainHeader />
                <Outlet />
                <Box sx={{ flexGrow: 1 }} />
                <Box>
                    <MainFooter />
                </Box>
            </Stack>
        </>
    );
}