import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'; // Ensure storage is imported
import userReducer from './slices/user';
import storyReducer from './slices/story';
import tributeReducer from './slices/tribute';
import settingReducer from './slices/setting';

// Configuration for Redux Persist
const rootPersistConfig = {
  key: 'root',
  storage,
};

// Combine reducers
const rootReducer = combineReducers({
  user: userReducer,
  story: storyReducer,
  tribute: tributeReducer,
  setting: settingReducer,
});

export { rootPersistConfig, rootReducer };
