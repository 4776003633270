import React from 'react';
import Iconify from '../../components/Iconify';

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const MenuConfig = () => {
  const token = localStorage.getItem('accessToken');

  const menuConfig = [
    !token && {
      title: 'SIGN IN',
      icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
      path: '/user/login',
    },
    // {
    //   title: 'CREATE A NEW WEBSITE',
    //   icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    //   path: '/',
    // },
    // {
    //   title: 'INVITE OTHERS',
    //   icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    //   path: '/',
    // },
    // {
    //   title: 'CONTACT SUPPORT',
    //   icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    //   path: '/',
    // },
  ].filter(Boolean);

  return menuConfig;
};

export default MenuConfig;
