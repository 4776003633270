/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';


const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Box sx={{ backgroundColor: '#f5f5f5', color: '#000', padding: '16px 0' }}>
        <Container maxWidth="lg">
          <Grid container alignItems="center">
            {/* Follow Us Section */}
            <Grid item xs={4} sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "20px",
            }}>
              <Typography variant="h6" sx={{ fontSize: '14px' }}>
                Follow Us
              </Typography>
              <IconButton aria-label="Instagram" color="inherit">
                <InstagramIcon />
              </IconButton>
              <IconButton aria-label="Facebook" color="inherit">
                <FacebookIcon />
              </IconButton>
            </Grid>

            {/* Links Section */}
            <Grid item xs={3}>
              <Grid container justifyContent="space-evenly">
                {/* <Link href="#" color="inherit" underline="hover" sx={{ marginRight: '16px', fontSize: '14px' }}>
                  Memorial Websites
                </Link>
                <Link href="#" color="inherit" underline="hover" sx={{ marginRight: '16px', fontSize: '14px' }}>
                  Partner With Us
                </Link>
                <Link href="#" color="inherit" underline="hover" sx={{ marginRight: '16px', fontSize: '14px' }}>
                  Resources
                </Link> */}
                <Link href="#" color="inherit" underline="hover" sx={{ marginRight: '16px', fontSize: '14px' }}>
                  Terms of Use
                </Link>
                <Link href="#" color="inherit" underline="hover" sx={{ marginRight: '16px', fontSize: '14px' }}>
                  Privacy Policy
                </Link>
                {/* <Link href="#" color="inherit" underline="hover" sx={{ marginRight: '16px', fontSize: '14px' }}>
                  Site Map
                </Link>
                <Link href="#" color="inherit" underline="hover" sx={{ fontSize: '14px' }}>
                  Contact Us
                </Link> */}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </RootStyle >
  );
}
