import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  story: [],
};

const storySlice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET CARD SUCCESS
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.story = action.payload;
    },
  },
});

export default storySlice.reducer;

// Thunk action for fetching cards
export function getStory() {
  return async (dispatch) => {
    dispatch(storySlice.actions.startLoading());
    try {
      const response = await axios.get('story');
      dispatch(storySlice.actions.getUserSuccess(response?.data?.data));
    } catch (error) {
      dispatch(storySlice.actions.hasError(error));
    }
  };
}
