/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container, Stack, MenuItem, alpha, Avatar, Typography, Divider } from '@mui/material';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSnackbar } from 'notistack';
import MenuPopover from '../../components/MenuPopover';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { IconButtonAnimate } from '../../components/animate';
import useAuth from '../../hooks/useAuth';
import useOffSetTop from '../../hooks/useOffSetTop';
import Image from '../../components/Image';
import whiteLogo from '../../assets/web_book.png';
import useResponsive from '../../hooks/useResponsive';
import cssStyles from '../../utils/cssStyles';
import { HEADER } from '../../config';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfigs from './MenuConfig';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: HEADER.MOBILE_HEIGHT,
    transition: theme.transitions.create(['height', 'background-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('md')]: {
        height: HEADER.MAIN_DESKTOP_HEIGHT,
    },
    background: 'none !important',
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    margin: 'auto',
    borderRadius: '50%',
    position: 'absolute',
    width: `calc(100% - 48px)`,
    boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
    const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
    const theme = useTheme();
    const { pathname } = useLocation();
    const isDesktop = useResponsive('up', 'md');
    const isHome = pathname === '/';
    const [open, setOpen] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('accessToken');
    const { user, logout } = useAuth();
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const currentUser = JSON.parse(localStorage.getItem('currentuser'));
    const navConfig = navConfigs();


    const handleClose = () => {
        setOpen(null);
    };
    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleLogout = async () => {
        try {
            await logout();
            localStorage.removeItem('currentuser');
            localStorage.removeItem('accessToken');
            navigate('/', { replace: true });
            if (isMountedRef.current) {
                handleClose();
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Unable to logout!', { variant: 'error' });
        }
    };


    return (
        <AppBar
            sx={{
                backgroundColor: '#ed1b23a1',
                zIndex: 100,
            }}
        >
            <ToolbarStyle
                disableGutters
                sx={{
                    justifyContent: 'space-between',
                    ...(isOffset && {
                        ...cssStyles(theme).bgBlur(),
                        height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
                    }),
                }}
            >
                <Container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        maxWidth: '100% !important',
                        margin: '0px',
                        padding: '0px',
                    }}
                >
                    <Box>
                        <Link to="/" sx={{ color: 'inherit', textDecoration: 'none' }}>
                        <Image visibleByDefault disabledEffect alt="login" src={whiteLogo}
                            sx={{
                                width: "90px",
                                height: "auto"
                            }}
                        />
                        </Link>
                    </Box>
                    <Box className="d-flex align-items-center gap-1" style={{ marginLeft: '26px' }}>
                        <Typography variant="div" className="position-relative d-flex align-items-center">
                            <Box sx={{ flexGrow: 1 }} />
                            {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
                            {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
                            {
                                token && (
                                    <>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <Box>
                                                <IconButtonAnimate
                                                    onClick={handleOpen}
                                                    sx={{
                                                        p: 0,
                                                        ...(open && {
                                                            '&:before': {
                                                                zIndex: 1,
                                                                content: "''",
                                                                width: '100%',
                                                                height: '100%',
                                                                borderRadius: '50%',
                                                                position: 'absolute',
                                                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                                                            },
                                                        }),
                                                    }}
                                                >

                                                    <Avatar
                                                        alt="Profile Image"
                                                        src={`${currentUser?.image || user?.image}`}
                                                        sx={{ width: 40, height: 40 }}
                                                    />

                                                </IconButtonAnimate>

                                                <MenuPopover
                                                    open={Boolean(open)}
                                                    anchorEl={open}
                                                    onClose={handleClose}
                                                    sx={{
                                                        p: 0,
                                                        mt: 1.5,
                                                        ml: 0.75,
                                                        width: '230px',
                                                        '& .MuiMenuItem-root': {
                                                            typography: 'body2',
                                                            borderRadius: 0.75,
                                                        },
                                                    }}
                                                >
                                                    <Stack sx={{ p: 2, width: 220 }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                            <Avatar
                                                                alt="Profile Image"
                                                                src={`${currentUser?.image || user?.image}`}
                                                                sx={{ width: 40, height: 40 }}
                                                            />
                                                            <Box sx={{ ml: 2 }}>
                                                                <Typography variant="subtitle1" noWrap>
                                                                    {currentUser?.first_name}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Divider sx={{ borderStyle: 'dashed', mt: 2 }} />
                                                        <MenuItem
                                                            onClick={() => {
                                                                navigate('/profile');
                                                            }}
                                                        >
                                                            <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Box component="span" sx={{ mr: 1, display: 'inline-flex', alignItems: 'center' }}>
                                                                    <BorderClearIcon />
                                                                </Box>
                                                                My Profile
                                                            </Box>
                                                        </MenuItem>

                                                        <Divider sx={{ borderStyle: 'dashed' }} />

                                                        <MenuItem onClick={handleLogout} sx={{ bgcolor: alpha('#FFC0CB', 0.3) }}>
                                                            <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Box component="span" sx={{ mr: 1, display: 'inline-flex', alignItems: 'center' }}>
                                                                    <LogoutIcon />
                                                                </Box>
                                                                Logout
                                                            </Box>
                                                        </MenuItem>
                                                    </Stack>
                                                </MenuPopover>
                                            </Box>
                                        </Box>
                                    </>
                                )
                            }
                        </Typography>
                    </Box>

                </Container>
            </ToolbarStyle>

            {isOffset && <ToolbarShadowStyle />}
        </AppBar>
    );
}


