import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';
import MainLayout from '../layouts/main';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Home /> },
    { path: '/admin/login', element: <Login /> },
    { path: '/user/register', element: <RegisterUser /> },
    { path: '/user/login', element: <LoginUser /> },
    // Admin Dashboard Routes Start
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <GeneralAnalytics /> },
        { path: 'user', element: <User /> },
        { path: 'adduser', element: <AddUser /> },
        { path: 'edituser/:id', element: <EditUser /> },
        { path: 'editadmin', element: <EditAdmin /> },
        { path: 'tribute', element: <Tribute /> },
        { path: 'addtribute', element: <AddTribute /> },
        { path: 'edittribute/:id', element: <EditTribute /> },
        { path: 'story', element: <Story /> },
        { path: 'addstory', element: <AddStory /> },
        { path: 'editstory/:id', element: <EditStory /> },
        { path: 'gallery', element: <Gallery /> },
        { path: 'addgallery', element: <AddGallery /> },
        { path: 'editgallery/:id', element: <EditGallery /> },
        { path: 'setting', element: <Setting /> },
        { path: 'addsetting', element: <AddSetting /> },
        {
          path: 'e-commerce',
          children: [{ element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true }],
        },
      ],
    },
    // Admin Dashboard Routes End

    // User Routes Start
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'buying/:id', element: <Buying /> },
        { path: 'contact', element: <Contact /> },
        { path: 'about', element: <About /> },
        { path: 'teamss', element: <Teamss /> },
        { path: 'allmatches/:id', element: <AllMatches /> },
        { path: 'checkout', element: <Checkout /> },
        { path: 'profile', element: <UserProfile /> },
      ],
    },
    // User Routes End
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const LoginUser = Loadable(lazy(() => import('../pages/auth/LoginUser')));
const RegisterUser = Loadable(lazy(() => import('../pages/auth/UserRegister')));
const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));

const EditAdmin = Loadable(lazy(() => import('../pages/dashboard/EditAdmin/EditAdmin')));
const UserProfile = Loadable(lazy(() => import('../pages/UserProfile/UserProfile')));

const User = Loadable(lazy(() => import('../pages/dashboard/User/User')));
const AddUser = Loadable(lazy(() => import('../pages/dashboard/User/AddUser')));
const EditUser = Loadable(lazy(() => import('../pages/dashboard/User/EditUser')));
const HomePage = Loadable(lazy(() => import('../pages/Home')));

const Contact = Loadable(lazy(() => import('../pages/Contact')));
const About = Loadable(lazy(() => import('../pages/About')));
const Buying = Loadable(lazy(() => import('../pages/Buying')));
const Teamss = Loadable(lazy(() => import('../pages/Team')));
const AllMatches = Loadable(lazy(() => import('../pages/AllMatches')));
const Checkout = Loadable(lazy(() => import('../pages/Checkout')));


const Story = Loadable(lazy(() => import('../pages/dashboard/Story/Story')));
const AddStory = Loadable(lazy(() => import('../pages/dashboard/Story/AddStory')));
const EditStory = Loadable(lazy(() => import('../pages/dashboard/Story/EditStory')));
const Tribute = Loadable(lazy(() => import('../pages/dashboard/Tribute/Tribute')));
const AddTribute = Loadable(lazy(() => import('../pages/dashboard/Tribute/AddTribute')));
const EditTribute = Loadable(lazy(() => import('../pages/dashboard/Tribute/EditTribute')));
const Gallery = Loadable(lazy(() => import('../pages/dashboard/Gallery/Gallery')));
const AddGallery = Loadable(lazy(() => import('../pages/dashboard/Gallery/AddGallery')));
const EditGallery = Loadable(lazy(() => import('../pages/dashboard/Gallery/EditGallery')));
const Setting = Loadable(lazy(() => import('../pages/dashboard/Setting/Setting')));
const AddSetting = Loadable(lazy(() => import('../pages/dashboard/Setting/AddSetting')));

