import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  users: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET CARD SUCCESS
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },
  },
});

export default userSlice.reducer;

// Thunk action for fetching cards
export function getUsers() {
  return async (dispatch) => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('user');
      dispatch(userSlice.actions.getUserSuccess(response?.data?.data));
    } catch (error) {
      dispatch(userSlice.actions.hasError(error));
    }
  };
}
